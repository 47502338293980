import { SVGProps } from 'react';

export interface IconsType {
	className?: string;
}

const Social_Posts = (props: SVGProps<SVGSVGElement>) => (
	<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<g clipPath="url(#clip0_9662_220339)">
			<path
				d="M4.1709 1.00663C3.87715 1.08475 3.70527 1.15038 3.49902 1.266C2.81465 1.65038 2.33027 2.31288 2.17402 3.0785C2.13027 3.28788 2.12402 3.67537 2.12402 5.98475V8.65037L2.1959 8.77537C2.36465 9.07537 2.7834 9.13162 3.04277 8.891C3.22402 8.71912 3.21777 8.83162 3.21777 6.016C3.21777 3.20663 3.21465 3.266 3.39277 2.90038C3.5709 2.541 3.94277 2.22538 4.33652 2.1035C4.53027 2.04413 4.6334 2.041 7.99902 2.041C11.3646 2.041 11.4678 2.04413 11.6615 2.1035C12.0553 2.22538 12.4271 2.541 12.6053 2.90038C12.7771 3.25038 12.7803 3.30037 12.7803 4.99412C12.7803 6.45037 12.7865 6.566 12.8396 6.68475C12.9271 6.87537 13.0646 6.95975 13.3115 6.97225C13.4834 6.98162 13.5303 6.97225 13.6209 6.90975C13.6803 6.87225 13.7584 6.79725 13.7928 6.75037C13.8584 6.66287 13.8584 6.63787 13.8584 4.90662C13.8584 3.16913 13.8584 3.14725 13.7865 2.9035C13.5115 1.95038 12.7709 1.23475 11.8334 1.00975C11.5865 0.950377 11.4553 0.947251 7.9834 0.950377C4.62715 0.950377 4.37402 0.956626 4.1709 1.00663Z"
				fill="url(#paint0_linear_9662_220339)"
			/>
			<path
				d="M5.85835 3.35342C5.24897 3.46279 4.75522 3.90967 4.58022 4.50654C4.5146 4.72842 4.5146 5.16592 4.58022 5.38779C4.73335 5.90342 5.11772 6.30654 5.6271 6.48154C5.90835 6.57842 6.34272 6.57842 6.63022 6.48154C7.06147 6.33779 7.3896 6.05029 7.58647 5.64092C8.0021 4.76904 7.56147 3.71904 6.64585 3.41904C6.41772 3.34404 6.0771 3.31592 5.85835 3.35342ZM6.38022 4.49717C6.4896 4.55342 6.58335 4.67842 6.63022 4.82529C6.73022 5.16904 6.37085 5.53779 6.02397 5.44717C5.61772 5.33467 5.4896 4.85967 5.7896 4.57842C5.9521 4.42529 6.1771 4.39404 6.38022 4.49717Z"
				fill="url(#paint1_linear_9662_220339)"
			/>
			<path
				d="M10.0896 7.3191C9.47402 7.45348 8.75527 7.91598 8.6084 8.27223C8.48027 8.57535 8.69902 8.9566 9.02402 9.00035C9.24277 9.02848 9.36152 8.98785 9.5584 8.80973C10.0365 8.38785 10.6146 8.2566 11.1709 8.44723C11.4584 8.54723 11.6334 8.6816 12.2178 9.25035L12.7584 9.77848L12.6928 9.97535C12.5084 10.5222 12.0053 10.9504 11.4365 11.041C11.324 11.0566 10.9115 11.0722 10.5146 11.0722H9.79902L8.6709 9.95348C7.7834 9.06598 7.4959 8.80035 7.31152 8.68785C6.86777 8.41598 6.4209 8.29098 5.88965 8.29098C5.33652 8.29098 4.82715 8.44098 4.40215 8.7316C4.15527 8.90035 2.68652 10.3347 2.60527 10.4847C2.58027 10.5316 2.56152 10.6504 2.56152 10.7535C2.56152 10.9847 2.64902 11.1254 2.97402 11.4347C3.3084 11.7535 3.64902 11.9441 4.12402 12.0816C4.3584 12.1504 4.36777 12.1504 7.8709 12.1597C11.7365 12.1691 11.6834 12.1722 12.1803 11.9785C12.7646 11.7535 13.3053 11.2629 13.5928 10.6972C13.799 10.2941 13.9084 9.75035 13.8459 9.4566C13.8053 9.27535 13.8021 9.27223 13.1146 8.58473C12.2271 7.70035 11.9771 7.52535 11.3678 7.35348C11.0334 7.2566 10.4459 7.24098 10.0896 7.3191ZM6.2959 9.45348C6.36465 9.4691 6.5209 9.53473 6.63965 9.59723C6.82402 9.69098 6.9709 9.82223 7.5459 10.391L8.2334 11.0722L6.37402 11.066C4.54277 11.0566 4.51152 11.0566 4.3334 10.9879C4.2334 10.9504 4.08652 10.8816 4.0084 10.8347L3.86777 10.7535L4.3459 10.2691C4.61152 10.0004 4.9084 9.7316 5.0084 9.66285C5.38652 9.41598 5.84902 9.33785 6.2959 9.45348Z"
				fill="url(#paint2_linear_9662_220339)"
			/>
			<path
				d="M2.98032 13.5379C2.66469 13.7285 2.60844 14.1472 2.86157 14.4035C3.05844 14.5972 3.30844 14.6035 3.50844 14.4191C3.63969 14.3004 3.68657 14.191 3.68657 14.0004C3.68657 13.616 3.28657 13.3504 2.98032 13.5379Z"
				fill="url(#paint3_linear_9662_220339)"
			/>
			<path
				d="M4.5584 13.5504C4.38965 13.6629 4.31152 13.8066 4.31152 14.0004C4.31152 14.0941 4.33027 14.2066 4.35527 14.2535C4.41777 14.3723 4.5584 14.4941 4.6834 14.5379C4.82715 14.5848 12.7396 14.5879 12.9146 14.5379C13.1678 14.4691 13.3396 14.1691 13.2803 13.9035C13.2459 13.7535 13.1678 13.6441 13.0303 13.5473L12.9271 13.4785H8.7959H4.66465L4.5584 13.5504Z"
				fill="url(#paint4_linear_9662_220339)"
			/>
			<path
				d="M2.95838 15.8973C2.80213 15.9535 2.72088 16.0285 2.65213 16.1816C2.53651 16.4316 2.61463 16.7223 2.83338 16.866L2.95526 16.9473L6.42401 16.941L9.88963 16.9316L9.98963 16.8566C10.2896 16.6316 10.2646 16.141 9.94276 15.941L9.82713 15.8691L6.45213 15.8629C3.66151 15.8566 3.05526 15.8629 2.95838 15.8973Z"
				fill="url(#paint5_linear_9662_220339)"
			/>
			<path
				d="M10.9584 15.8973C10.8022 15.9535 10.7209 16.0285 10.6522 16.1816C10.5397 16.4285 10.6084 16.7035 10.8209 16.8566L10.9459 16.9473H11.9991C13.0397 16.9473 13.0522 16.9473 13.1584 16.8754C13.4084 16.7098 13.4772 16.3223 13.2991 16.091C13.1334 15.8723 13.1272 15.8691 12.0459 15.8629C11.2834 15.8566 11.0522 15.8629 10.9584 15.8973Z"
				fill="url(#paint6_linear_9662_220339)"
			/>
		</g>
		<defs>
			<linearGradient
				id="paint0_linear_9662_220339"
				x1="6.71455"
				y1="1.348"
				x2="14.3957"
				y2="7.15301"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#016DEA" />
				<stop offset="1" stopColor="#FA0101" />
			</linearGradient>
			<linearGradient
				id="paint1_linear_9662_220339"
				x1="5.78784"
				y1="3.49531"
				x2="8.38788"
				y2="4.84833"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#016DEA" />
				<stop offset="1" stopColor="#FA0101" />
			</linearGradient>
			<linearGradient
				id="paint2_linear_9662_220339"
				x1="6.98287"
				y1="7.51031"
				x2="11.7301"
				y2="13.2237"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#016DEA" />
				<stop offset="1" stopColor="#FA0101" />
			</linearGradient>
			<linearGradient
				id="paint3_linear_9662_220339"
				x1="3.08755"
				y1="13.5293"
				x2="3.91247"
				y2="13.9221"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#016DEA" />
				<stop offset="1" stopColor="#FA0101" />
			</linearGradient>
			<linearGradient
				id="paint4_linear_9662_220339"
				x1="7.82474"
				y1="13.5325"
				x2="8.3047"
				y2="15.5825"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#016DEA" />
				<stop offset="1" stopColor="#FA0101" />
			</linearGradient>
			<linearGradient
				id="paint5_linear_9662_220339"
				x1="5.57253"
				y1="15.9143"
				x2="6.11952"
				y2="17.9085"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#016DEA" />
				<stop offset="1" stopColor="#FA0101" />
			</linearGradient>
			<linearGradient
				id="paint6_linear_9662_220339"
				x1="11.6941"
				y1="15.9143"
				x2="12.7212"
				y2="17.2937"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#016DEA" />
				<stop offset="1" stopColor="#FA0101" />
			</linearGradient>
			<clipPath id="clip0_9662_220339">
				<rect width="16" height="16" fill="white" transform="translate(0 0.947266)" />
			</clipPath>
		</defs>
	</svg>
);

export default Social_Posts;
