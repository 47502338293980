import { SVGProps } from 'react';

export interface IconsType {
	className?: string;
}

const Social_Group = (props: SVGProps<SVGSVGElement>) => (
	<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M6.66667 2.94727H2V7.61393H6.66667V2.94727Z"
			stroke={props.stroke || 'url(#paint0_linear_9662_220387)'}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M14 2.94727H9.33337V7.61393H14V2.94727Z"
			stroke={props.stroke || 'url(#paint1_linear_9662_220387)'}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M14 10.2808H9.33337V14.9474H14V10.2808Z"
			stroke={props.stroke || 'url(#paint2_linear_9662_220387)'}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M6.66667 10.2808H2V14.9474H6.66667V10.2808Z"
			stroke={props.stroke || 'url(#paint3_linear_9662_220387)'}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<defs>
			<linearGradient
				id="paint0_linear_9662_220387"
				x1="3.82562"
				y1="7.38413"
				x2="7.60003"
				y2="5.41718"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#016DEA" />
				<stop offset="1" stopColor="#FA0101" />
			</linearGradient>
			<linearGradient
				id="paint1_linear_9662_220387"
				x1="11.159"
				y1="7.38413"
				x2="14.9334"
				y2="5.41718"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#016DEA" />
				<stop offset="1" stopColor="#FA0101" />
			</linearGradient>
			<linearGradient
				id="paint2_linear_9662_220387"
				x1="11.159"
				y1="14.7176"
				x2="14.9334"
				y2="12.7507"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#016DEA" />
				<stop offset="1" stopColor="#FA0101" />
			</linearGradient>
			<linearGradient
				id="paint3_linear_9662_220387"
				x1="3.82562"
				y1="14.7176"
				x2="7.60003"
				y2="12.7507"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#016DEA" />
				<stop offset="1" stopColor="#FA0101" />
			</linearGradient>
		</defs>
	</svg>
);

export default Social_Group;
