import { format, intervalToDuration, parse, parseISO } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { ar, enUS } from 'date-fns/locale';

// The output: 24-Jan-2025 11:35 PM
export const formatDateWithShortMonth = (
	date: string | Date | undefined,
	showTime: boolean = true,
	localTimeOnly: boolean = false
): string => {
	if (!date) return '';

	try {
		let parsedDate: Date;

		if (date instanceof Date) {
			parsedDate = date;
		} else if (typeof date === 'string') {
			if (date.includes('T') || /^\d{4}-\d{2}-\d{2}$/.test(date)) {
				parsedDate = parseISO(date);
			} else if (/^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/.test(date)) {
				parsedDate = parse(date, 'yyyy-MM-dd HH:mm:ss', new Date());
			} else {
				parsedDate = parse(date, 'dd-MMM-yyyy hh:mm a', new Date());
			}
		} else {
			throw new Error('Unsupported date format');
		}

		const language = localStorage.getItem('language') || 'en';

		const locale = language === 'ar' ? ar : enUS;

		if (localTimeOnly) {
			return format(parsedDate, 'hh:mm a', { locale });
		}

		const formatString = showTime ? 'dd-MMM-yyyy hh:mm a' : 'dd-MMM-yyyy';

		return format(parsedDate, formatString, { locale });
	} catch (error) {
		console.error('Error parsing or formatting date:', error);
		return '';
	}
};

// The output too but takes just seconds: 24-Jan-2025 11:35 PM
export function formatTimestampWithDateFns(timestamp: number): string {
	if (!timestamp) return '';

	if (timestamp < 10000000000) {
		timestamp *= 1000;
	}

	try {
		return format(new Date(timestamp), 'dd-MM-yyyy hh:mm a');
	} catch (error) {
		console.error('Error formatting timestamp:', error);
		return '';
	}
}

// Return "0 H: 0 M:, 0 S" if the input is -1 or any negative number
export function formatTimeBySeconds(seconds: number): string {
	if (seconds < 0) {
		return '0 H: 0 M: 0 S';
	}

	const duration = intervalToDuration({
		start: 0,
		end: seconds * 1000,
	});

	const hours = duration.hours || 0;
	const minutes = duration.minutes || 0;
	const secs = duration.seconds || 0;

	return `${hours} H: ${minutes} M: ${secs} S`;
}

// The output: 24-January-2025 11:35 PM
export const formatDateForDisplay = (date: string | Date): string => {
	try {
		const parsedDate = typeof date === 'string' ? parseISO(date) : date;
		return format(parsedDate, 'dd-MMMM-yyyy hh:mm a');
	} catch (error) {
		console.error('Invalid date format:', error);
		return '';
	}
};

// The output like: 25th Jan 2025
export function formatDateTh(dateInput?: string | Date | undefined): string {
	if (!dateInput) {
		return '';
	}

	let date: Date;

	if (dateInput instanceof Date) {
		date = dateInput;
	} else if (typeof dateInput === 'string') {
		date = parseISO(dateInput);
	} else {
		throw new Error('Invalid date input');
	}

	return format(date, 'do MMM yyyy');
}

export const convertToUTCForApi = (date: string) => {
	const parsedTime = parseISO(date);

	const formattedTime = formatInTimeZone(parsedTime, 'UTC', "yyyy-MM-dd'T'HH:mm:ss");

	return formattedTime;
};

export const getCurrentUTC = () => {
	const currentDate = new Date(); // Current date and time
	const timeZone = 'Etc/UTC'; // Specify UTC timezone

	// Format the date in UTC timezone
	const formattedUtcDate = formatInTimeZone(currentDate, timeZone, "yyyy-MM-dd'T'HH:mm:ss");
	return formattedUtcDate;
};
