import { SVGProps } from 'react';

export interface IconsType {
	className?: string;
}

const ShareMini = (props: SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={17} height={17} fill="none" {...props}>
		<g strokeLinecap="round" strokeLinejoin="round" clipPath="url(#a)">
			<path stroke={props.stroke || 'url(#b)'} d="M12.5 6.277a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z" />
			<path stroke={props.stroke || 'url(#c)'} d="M4.5 10.945a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z" />
			<path stroke={props.stroke || 'url(#d)'} d="M12.5 15.613a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z" />
			<path stroke={props.stroke || 'url(#e)'} d="m6.227 9.953 4.553 2.654" />
			<path stroke={props.stroke || 'url(#f)'} d="M10.773 5.285 6.227 7.938" />
		</g>
		<defs>
			<linearGradient id="b" x1={12.065} x2={15.3} y1={6.08} y2={4.394} gradientUnits="userSpaceOnUse">
				<stop stopColor="#016DEA" />
				<stop offset={1} stopColor="#FA0101" />
			</linearGradient>
			<linearGradient id="c" x1={4.065} x2={7.3} y1={10.748} y2={9.062} gradientUnits="userSpaceOnUse">
				<stop stopColor="#016DEA" />
				<stop offset={1} stopColor="#FA0101" />
			</linearGradient>
			<linearGradient id="d" x1={12.065} x2={15.3} y1={15.416} y2={13.73} gradientUnits="userSpaceOnUse">
				<stop stopColor="#016DEA" />
				<stop offset={1} stopColor="#FA0101" />
			</linearGradient>
			<linearGradient id="e" x1={8.008} x2={10.61} y1={12.476} y2={10.149} gradientUnits="userSpaceOnUse">
				<stop stopColor="#016DEA" />
				<stop offset={1} stopColor="#FA0101" />
			</linearGradient>
			<linearGradient id="f" x1={8.005} x2={10.607} y1={7.808} y2={5.485} gradientUnits="userSpaceOnUse">
				<stop stopColor="#016DEA" />
				<stop offset={1} stopColor="#FA0101" />
			</linearGradient>
			<clipPath id="a">
				<path fill="#fff" d="M.5.945h16v16H.5z" />
			</clipPath>
		</defs>
	</svg>
);
export default ShareMini;
