import { SVGProps } from 'react';

export interface IconsType {
	className?: string;
}

const Order = (props: SVGProps<SVGSVGElement>) => (
	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M7.28124 0.766017C6.88749 0.803517 6.30936 0.919143 5.86561 1.05664C4.01561 1.61602 2.44374 2.94414 1.56561 4.68789C1.03436 5.74102 0.799988 6.74102 0.799988 7.96289C0.799988 8.89102 0.928113 9.66289 1.21874 10.4473C1.45624 11.0973 1.92186 11.9254 2.34061 12.4441C2.42811 12.5535 2.49999 12.6535 2.49999 12.6691C2.49999 12.7098 2.41561 12.7035 2.21874 12.6504C1.98124 12.5879 1.94686 12.591 1.84061 12.6941C1.76874 12.7691 1.74999 12.8129 1.74999 12.9223C1.74999 13.1473 1.78749 13.1691 2.72499 13.416C3.18124 13.5348 3.59374 13.6348 3.64061 13.6348C3.74374 13.6348 3.90624 13.4785 3.90624 13.3785C3.90624 13.3379 3.80624 12.9254 3.68749 12.4629C3.44061 11.5191 3.42186 11.4785 3.18436 11.4785C3.08436 11.4785 3.03749 11.5004 2.96249 11.5723C2.86249 11.6723 2.85936 11.7066 2.92186 12.0004C2.96249 12.1785 2.86874 12.1223 2.66874 11.8441C1.99999 10.9285 1.58436 9.88164 1.43436 8.72852C1.38436 8.35352 1.38749 7.55352 1.43436 7.16602C1.58436 5.99414 2.03749 4.88477 2.74374 3.94727C2.98436 3.62852 3.55311 3.05039 3.87499 2.79102C5.41561 1.56289 7.42186 1.08164 9.34374 1.48164C9.90936 1.59727 10.6312 1.85664 11.0531 2.09102C11.4062 2.28789 11.6562 2.19727 11.6562 1.87539C11.6562 1.69727 11.4531 1.56289 10.7562 1.27539C9.65936 0.825393 8.47499 0.653518 7.28124 0.766017Z"
			fill={props.fill || props.stroke || 'url(#paint0_linear_9759_139553)'}
		/>
		<path
			d="M12.2812 2.28176C12.1906 2.31614 12.125 2.39426 12.1094 2.49114C12.0969 2.55676 12.1656 2.86926 12.3125 3.43176C12.5562 4.37551 12.5781 4.41614 12.8156 4.41614C12.9125 4.41614 12.9625 4.39426 13.0344 4.32551C13.1344 4.22551 13.1437 4.16926 13.0906 3.95989C13.0281 3.71926 13.0969 3.72864 13.2875 3.98489C14.7937 5.99114 15.0344 8.64739 13.9156 10.9005C13.0656 12.6099 11.4781 13.888 9.62499 14.3536C8.13749 14.7286 6.59999 14.5818 5.20312 13.9318C4.86874 13.7755 4.73749 13.7599 4.61562 13.8536C4.50937 13.938 4.48437 14.0693 4.54374 14.213C4.58749 14.313 4.62812 14.3411 4.88124 14.4599C5.89374 14.9318 6.85312 15.1443 7.96874 15.1474C8.84062 15.1505 9.45624 15.0536 10.2656 14.788C12.6344 14.0099 14.4406 12.0443 15.0156 9.61926C15.5125 7.52239 15.0562 5.30989 13.775 3.60989C13.6375 3.42551 13.5156 3.25676 13.5094 3.23489C13.4875 3.18489 13.5437 3.18801 13.7312 3.24426C13.9625 3.31301 14.0531 3.30364 14.1594 3.20051C14.3156 3.04114 14.2719 2.78176 14.0719 2.70364C13.9469 2.65364 12.425 2.25989 12.3719 2.26301C12.3469 2.26301 12.3062 2.27239 12.2812 2.28176Z"
			fill={props.fill || props.stroke || 'url(#paint1_linear_9759_139553)'}
		/>
		<path
			d="M6.1125 5.21306C4.93437 5.62556 4.28125 5.86931 4.2375 5.91306L4.17188 5.98181L4.18125 7.94119C4.19062 9.75369 4.19687 9.90681 4.24687 9.97869C4.29375 10.0443 4.6125 10.1662 6.09687 10.6881C7.08437 11.0349 7.93437 11.3193 7.98437 11.3193C8.0375 11.3224 8.8875 11.0412 9.875 10.6943C11.2562 10.2131 11.6875 10.0506 11.7437 9.99119L11.8125 9.91306V7.94744V5.98181L11.7437 5.90681C11.6875 5.84744 11.2469 5.68181 9.85937 5.20056C8.8625 4.85369 8.01875 4.57244 7.98437 4.57556C7.95 4.57556 7.10937 4.86306 6.1125 5.21306ZM8.4125 5.34431C8.63125 5.42556 8.80937 5.50681 8.80312 5.52244C8.79687 5.53806 8.2375 5.74431 7.55937 5.98181L6.32812 6.41306L5.92187 6.27869C5.68125 6.20056 5.51562 6.12869 5.51562 6.10369C5.51562 6.08181 5.9875 5.90056 6.75 5.63181C7.42812 5.39431 7.99062 5.20056 7.99687 5.19744C8.00312 5.19744 8.19062 5.26306 8.4125 5.34431ZM10.1437 5.93494C10.4594 6.05056 10.5281 6.08806 10.4937 6.12244C10.4812 6.13181 9.91562 6.33494 9.2375 6.57244L8 7.00681L7.67187 6.89431C7.475 6.82869 7.34687 6.76931 7.35312 6.74744C7.3625 6.72244 9.73125 5.83181 9.81562 5.82244C9.825 5.82244 9.975 5.87244 10.1437 5.93494ZM5.32812 6.72869C5.58437 6.81306 5.80312 6.90056 5.81875 6.92244C5.83125 6.94431 5.84375 7.29119 5.84375 7.69119V8.41931L5.92187 8.49431C6.03125 8.60681 6.25312 8.60369 6.35937 8.49119C6.4375 8.40681 6.4375 8.40056 6.4375 7.78806C6.4375 7.44744 6.45 7.16306 6.46562 7.15369C6.4875 7.14119 7.4125 7.43494 7.63437 7.52556C7.68437 7.54744 7.6875 7.65056 7.6875 9.05994C7.6875 10.4474 7.68437 10.5724 7.63437 10.5724C7.59062 10.5724 5.14062 9.72244 4.88437 9.61619L4.78125 9.57556V8.07556C4.78125 6.86931 4.79062 6.57244 4.82187 6.57244C4.84375 6.57244 5.07187 6.64119 5.32812 6.72869ZM11.2125 8.07869L11.2031 9.58181L9.89062 10.0474C8.2625 10.6224 8.34687 10.5943 8.30937 10.5381C8.29375 10.5131 8.28125 9.85369 8.28125 9.03494C8.28125 7.90056 8.29062 7.56931 8.32187 7.55056C8.3875 7.50994 11.1062 6.57556 11.1656 6.57244C11.2156 6.57244 11.2187 6.65681 11.2125 8.07869Z"
			fill={props.fill || props.stroke || 'url(#paint2_linear_9759_139553)'}
		/>
		<path
			d="M10 8.93483C9.73438 9.02858 9.48438 9.13795 9.44688 9.17233C9.35313 9.25983 9.35001 9.4692 9.43751 9.5817C9.54688 9.72233 9.63126 9.71608 10.1625 9.52858C10.7656 9.3192 10.8281 9.28483 10.8688 9.1692C10.9125 9.04733 10.8656 8.87233 10.7719 8.8067C10.6625 8.72858 10.5094 8.75358 10 8.93483Z"
			fill={props.fill || props.stroke || 'url(#paint3_linear_9759_139553)'}
		/>
		<defs>
			<linearGradient
				id="paint0_linear_9759_139553"
				x1="5.04699"
				y1="1.36793"
				x2="14.4116"
				y2="5.47421"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#016DEA" />
				<stop offset="1" stopColor="#FA0101" />
			</linearGradient>
			<linearGradient
				id="paint1_linear_9759_139553"
				x1="8.69813"
				y1="2.89746"
				x2="17.9666"
				y2="6.90861"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#016DEA" />
				<stop offset="1" stopColor="#FA0101" />
			</linearGradient>
			<linearGradient
				id="paint2_linear_9759_139553"
				x1="7.16092"
				y1="4.90762"
				x2="12.9877"
				y2="8.34792"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#016DEA" />
				<stop offset="1" stopColor="#FA0101" />
			</linearGradient>
			<linearGradient
				id="paint3_linear_9759_139553"
				x1="9.9653"
				y1="8.80987"
				x2="10.8576"
				y2="9.57835"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#016DEA" />
				<stop offset="1" stopColor="#FA0101" />
			</linearGradient>
		</defs>
	</svg>
);

export default Order;
